<template>
  <div class="my-info">
    <user v-if="user_info.type == 1" />
    <org v-if="user_info.type == 2"  />
  </div>
</template>

<script>
import org from './org.vue'
import user from './user.vue'
import { mapState } from 'vuex'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      user_info: state => state.user_info,
    })
  },
  components: { org, user }
}
</script>

<style>

</style>