<template>
  <div class="my-info-warp">
    <div class="left">
      <img :src="form.avatar || '/images/user-avatar-default.png' " class="avatar">
      <!-- <div v-if="from != 'yg' && env != 'private'" class="wx"  @click="useWxInfo">使用微信头像和昵称<img src="/images/icon-arrow-right.svg"/></div> -->
      <div class="edit-avatar" @click="editAvatar">更换</div>
    </div>
    <div class="right">
      <a-form layout="vertical" :form="form">
        <a-form-item
          label="用户名"
          class="item"
        > 
          <a-input v-model="form.nickname" placeholder="用一个独特的名字，让大家记住你" :maxLength="16" @input.native="inputHandler" />
          <div class="limit" :class="{ 'max-count': form.nickname.length === 16}">{{form.nickname.length}}/16</div>
        </a-form-item>
        <template v-if="env != 'private'">
          <a-form-item
            label="签名"
            class="item"
          >
            <a-input v-model="form.signature" placeholder="写写你的人生格言、当下状态"  :maxLength="30"/>
            <div class="limit" :class="{ 'max-count': form.signature.length === 30}">{{form.signature.length}}/30</div>
          </a-form-item>
          <div class="title">
            <span class="line"/>
            基本资料
          </div>
          <div class="item-flex">
            <div>
              <a-form-item
              label="生日"
              >
                <a-date-picker v-model="form.birth" style="width: 284px" placeholder="请选择"/>
              </a-form-item>
            </div>
            <div>
              <a-form-item
                label="性别"
              >
                <a-select v-model="form.sex" style="width: 284px" placeholder="请选择">
                  <a-select-option :value="1">
                    男
                  </a-select-option>
                  <a-select-option :value="2">
                    女
                  </a-select-option>
                  <a-select-option :value="0">
                    保密
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
          </div>
          <a-form-item
            label="居住地"
            >
            <a-cascader :options="areaData" v-model="form.live_place" placeholder="你目前住在哪儿"/>
          </a-form-item>
          <div class="item-flex">
            <div>
              <a-form-item
              label="工作年限"
              >
                <a-date-picker v-model="form.years_of_work" style="width: 284px" placeholder="你从什么时候开始工作的"/>
              </a-form-item>
            </div>
            <div>
              <a-form-item
                label="职业"
              >
                <a-input v-model="form.professional" placeholder="填写你的职业职位，将会遇到更多同仁"  :maxLength="20"/>
              </a-form-item>
            </div>
          </div>
          <div class="title">
            <span class="line"/>
            个人简介
          </div>
          <a-form-item
            class="item"
          >
            <a-textarea v-model="form.introduce" :rows="4" placeholder="写一段有趣的个人介绍，被关注的概率会提升3倍，大家都喜欢写职业经历、擅长领域、个人喜好..." style="height: 132px;" :maxLength="300"/>
            <div class="desc-limit limit" :class="{ 'max-count': form.introduce.length === 300}">{{form.introduce.length}}/300</div>
          </a-form-item>
        </template>
        <div class="submit-btn" @click="handleSubmit">保存</div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { areaData } from './areaData.js'
import { mapState } from 'vuex'
export default {
  name: 'myInfo',
  data () {
    return {
      areaData,
      form: {
        avatar: '',
        nickname: '',
        signature: '',
        birth: '',
        sex: 0,
        live_place: [],
        years_of_work: '',
        professional: '',
        introduce: ''
      },
      wechat_data: {}
    }
  },
  created () {
    this.initPage()
    this.getUserInfo()
  },
  computed: {
    ...mapState({
      env: state => state.env,
    })
  },
  methods: {
    inputHandler(e) {
      this.form.nickname = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
    },
    //限制输入特殊字符
    keyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
    },
    // 只能输入汉字、英文、数字
    keydown(e) {
      // e.target.value = e.target.value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,"");
    },
    async handleSubmit () {
      const params = _.cloneDeep(this.form)
      params.live_place = params.live_place.join('/')
      params.birth = this.formatDate(params.birth)
      params.years_of_work = this.formatDate(params.years_of_work)
      if (params.nickname === '') {
        return this.$message.error('请填写用户名')
      }
      await AuthService.editUserInfo(params)
      this.$message.success('修改用户信息成功')
      this.initUserInfo()
      this.getUserInfo()
    },
    async getUserInfo () {
      const res = await AuthService.userInfo()
      const avatar = res.avatar
      const nickname = res.nickname
      const user_deep_info = res.user_deep_info || {}
      this.form = {
        avatar,
        nickname,
        signature: user_deep_info.signature || '',
        birth: user_deep_info.birth || '',
        introduce: user_deep_info.introduce || '',
        professional: user_deep_info.professional || '',
        sex: user_deep_info.sex || 0,
        years_of_work: user_deep_info.years_of_work || '',
        live_place: user_deep_info.live_place ? user_deep_info.live_place.split('/') : []
      }
    },
    async initPage() {
      if (this.$route.query.key) {
        let res = await AuthService.getWechatCallBackInfo({...this.$route.query})
        if (res) {
          this.wechat_data = res
          this.doBindWechat()
        }
      }
    },
    // 使用wx信息
    async useWxInfo () {
      if (this.user_info.wechat_nickname) { //解绑
        let res = await AuthService.bpyCheckAccountIsBindPhone()
        if (res.status == 1) {
          this.$message.success('解除绑定微信成功')
        }
      } else {
        let redirect_uri = encodeURIComponent(`${location.origin}/bpy_myInfo?key=${new Date().getTime()}`)
        location.href = `/getAuthUrl?redirectURI=${redirect_uri}`
      }
    },
    async doBindWechat() {
      let res = await AuthService.bpyBindWechat({user_info: JSON.stringify(this.wechat_data)}).catch(err => {
        if (err.errCode == 536) {
          this.bind_wx_fail_show = true
          this.bind_wx_fail_text = err.data
        } else {
          this.$message.error('操作失败')
        }
      })
      if (res) {
        this.$message.success('绑定微信成功')
        this.initUserInfo()
      }
    },
    editAvatar() {
      Upload2Oss({}, async arr => {
        if (arr && arr[0]) {
          this.form.avatar = arr[0].url
        }
      })
    },
    formatDate (val) {
      return new Date(val).toLocaleDateString().replace(/\//g,"-")
    }
  },
}
</script>