<template>
  <div class="my-org-warp">
    <div class="org-tishi">
      <img src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/bb/bb980f2107a7b321885ec1ccd147dc31/img-accreditation%403x.jpg" alt="" class="bg">
      <img class="icon" src="https://bpy-store.oss-cn-hangzhou.aliyuncs.com/ppc/d4/d43b44878510dee869c6b0c6bc53e7a9/icon-verified-business.svg" alt="">
      <span>已完成企业认证</span>
    </div>
    <div class="form-item item-center">
      <div class="label">头像</div>
      <div class="item-con">
        <img class="user-icon" :src="form.avator || '/images/user-avatar-default.png'" alt="">
        <span class="change-icon" @click="editAvatar">更换头像</span>
      </div>
    </div>
    <div class="form-item item-center">
      <div class="label">用户名</div>
      <div class="item-con">
        <a-input v-model="form.username" :maxLength="16" @input.native="inputHandler" />
        <div class="limit" :class="{ 'max-count': form.username.length === 16}">{{form.username.length}}/16</div>
      </div>
    </div>
    <div class="form-item">
      <div class="label">简介</div>
      <div class="item-con">
        <a-textarea v-model="form.intro" :rows="4" style="height: 132px;" :maxLength="300"/>
        <div class="desc-limit limit" :class="{ 'max-count': form.intro.length === 300}">{{form.intro.length}}/300</div>
      </div>
    </div>
    <div class="form-item">
      <div class="label">公司介绍</div>
      <div class="item-con">
        <div class="editor-con">
          <bpy-editor ref="bpy-editor" :value="form.content" @editorChange="editorChange" />
        </div>
      </div>
    </div>
    <div class="btn-con">
      <span class="save" @click="handleSubmit">保存</span>
    </div>
  </div>
</template>

<script>
import BpyEditor from '../../components/BpyEditor'
export default {
  data () {
    return {
      form: {
        username: '',
        avator: '',
        intro: '',
        content: []
      }
    }
  },
  components: { BpyEditor },
  created () {
    this.getUserInfo()
  },
  methods: {
    inputHandler(e) {
      this.form.username = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
    },
    editorChange (content) {
      this.form.content = content
    },
    editAvatar() {
      Upload2Oss({}, async arr => {
        if (arr && arr[0]) {
          this.form.avator = arr[0].url
        }
      })
    },
    async getUserInfo () {
      const res = await AuthService.userEnterpriseInfo()
      this.form = {
        username: res.username || '',
        avator: res.avator || '',
        intro: res.intro || '',
        content: JSON.parse(res.enterprise_intro ? res.enterprise_intro.content : '[]')
      }
      this.$refs['bpy-editor'].setContnet(this.form.content)
    },
    async handleSubmit () {
      let params = {
        avator: this.form.avator,
        username: this.form.username,
        intro: this.form.intro,
        introduction_content: JSON.stringify(this.form.content || []),
      }
      if (params.username === '') {
        return this.$message.error('请填写用户名')
      }
      await AuthService.addOrUpdate(params)
      this.$message.success('修改用户信息成功')
      this.initUserInfo()
      this.getUserInfo()
    },
  }
}
</script>

<style>

</style>